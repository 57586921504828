import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const Yahtzee: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Yahtzee Game" />
    <h1>Yahtzee Game</h1>
    <p>
      In second year of university, I got my first exposure to javascript. The
      project just had to be something interactive with javascript. I decided to
      recreate Yahtzee.
    </p>
    <p>
      Check it out in the iFrame or you can vist it on{" "}
      <a href="https://liammyles.github.io/past-static-projects/yahtzee/">
        Github Pages Yahtzee Game
      </a>
    </p>
    <ResponsiveIFrame src="https://liammyles.github.io/past-static-projects/yahtzee/" />
  </StandardLayout>
)

export default Yahtzee
